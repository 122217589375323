import {
  HasEventEnum,
  SqlOperator,
  HasPartnerStatusEnum,
  EventPartner,
  EventCyclePartner,
  HasEventCycleEnum,
  Event,
  Maybe,
  User,
  Stream,
} from "@/generated/graphql";
import { fromISOToString } from "@/utils/date-time";

export function getPartnerEventsTableItems(events: Array<EventPartner> | []) {
  return events.map((eventPartner) => {
    return {
      id: eventPartner.id,
      date_from: fromISOToString(eventPartner.event.date_from, "DD"),
      public_name: eventPartner.event.public_name,
      city: eventPartner.event.city?.name,
      participantCount: eventPartner.event.participantCount,
      status: eventPartner.partnerStatus.status,
    };
  });
}

export function getPartnerEventCyclesTableItems(
  events: Array<EventCyclePartner> | []
) {
  return events.map((eventPartner) => {
    return {
      id: eventPartner.id,
      date_from: fromISOToString(eventPartner.eventCycle.date_from, "DD"),
      public_name: eventPartner.eventCycle.public_name,
      city: eventPartner.eventCycle.city?.name,
      participantCount: eventPartner.eventCycle.participantCount,
      status: eventPartner.partnerStatus.status,
    };
  });
}

export const VISIBLE_HEADERS_VALUES = {
  status: "status",
  name: "public_name",
  id: "id",
  city: "city",
  participantCount: "participantCount",
  dateFrom: "date_from",
};

export const PARTNERS_EVENTS_TABLE_HEADERS = [
  {
    text: "ID",
    value: VISIBLE_HEADERS_VALUES.id,
    width: 100,
    sortable: false,
  },
  { text: "Название", value: VISIBLE_HEADERS_VALUES.name, sortable: false },
  { text: "Город", value: VISIBLE_HEADERS_VALUES.city, sortable: false },
  {
    text: "Дата начала",
    value: VISIBLE_HEADERS_VALUES.dateFrom,
    sortable: false,
  },
  { text: "Статус", value: VISIBLE_HEADERS_VALUES.status, sortable: false },
  {
    text: "Пользователи",
    value: VISIBLE_HEADERS_VALUES.participantCount,
    sortable: false,
  },
];

export function getPartnerEventsFilters(filters: {
  public_name: string;
  status: string;
}) {
  return {
    hasEvent: {
      column: HasEventEnum.PublicName,
      operator: SqlOperator.Ilike,
      value: filters.public_name ? `%${filters.public_name.trim()}%` : null,
    },
    hasPartnerStatus: {
      column: HasPartnerStatusEnum.Status,
      operator: SqlOperator.Ilike,
      value: filters.status ? `%${filters.status.trim()}%` : null,
    },
  };
}

export function getPartnerEventCyclesFilters(filters: {
  public_name: string;
  status: string;
}) {
  return {
    hasEventCycle: {
      column: HasEventCycleEnum.PublicName,
      operator: SqlOperator.Ilike,
      value: filters.public_name ? `%${filters.public_name.trim()}%` : null,
    },
    hasPartnerStatus: {
      column: HasPartnerStatusEnum.Status,
      operator: SqlOperator.Ilike,
      value: filters.status ? `%${filters.status.trim()}%` : null,
    },
  };
}

const NO_DATA = "Нет данных";

/**
 * Возвращает список отформатированных данных мероприятия
 */

export function getFormattedEventId(event: Event) {
  return {
    ...event,
    participants: getFormattedParticipants(event.participants, event.stream),
  };
}

/**
 * Возвращает список отформатированных участников мероприятия
 */
function getFormattedParticipants(
  participants: Maybe<Array<User>> | undefined,
  stream: Maybe<Stream> | undefined
) {
  if (participants?.length) {
    return participants?.map((participant) => {
      const streamUser = stream?.streamUsers?.find(
        (user) => Number(user.user_id) === Number(participant.id)
      );

      return {
        ...participant,
        duration: getFormattedTime(streamUser?.duration),
        first_connected_at: getFormattedDateTime(
          streamUser?.first_connected_at
        ),
        last_disconnected_at: getFormattedDateTime(
          streamUser?.last_disconnected_at
        ),
      };
    });
  }
  return [];
}

/**
 * Возвращает отформатированную дату или сообщение о ее отсутствии
 */
function getFormattedDateTime(
  dateTime: Maybe<string | number> | undefined
): string {
  return dateTime ? fromISOToString(dateTime, "yyyy-MM-dd, HH:mm:ss") : NO_DATA;
}

/**
 * Возвращает отформатированное время или сообщение о его отсутствии
 */
function getFormattedTime(time: Maybe<string | number> | undefined): string {
  return time ? `${time} мин` : "0 мин";
}
