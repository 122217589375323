<template>
  <v-card>
    <div class="d-flex align-center pt-3 px-3">
      <slot name="header"></slot>
      <!--       TODO: переписать Settings на классический api компонент-->
      <div class="ml-auto">
        <Settings :headers="headers" v-model="visibleHeaders" />
      </div>
    </div>
    <v-data-table
      v-model="selectedRows"
      :headers="visibleHeaders"
      :items="items"
      :loading="loading"
      :show-select="isShowSelect"
      :page="pagination.page"
      :items-per-page="pagination.first"
      :server-items-length="itemsServerLength"
      @update:page="changePage"
      @update:items-per-page="changePerPage"
      @click:row="$emit('click-row', $event)"
    >
      <template #body.prepend>
        <tr>
          <td v-if="isShowSelect"></td>
          <slot name="body.prepend" />
        </tr>
      </template>
      <template
        v-for="header of headers"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <slot :name="`item.${header.value}`" v-bind="{ item }">
          {{ item[header.value] }}
        </slot>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Settings from "@components/parts/tables/Settings";
export default {
  components: { Settings },

  props: {
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    itemsServerLength: { type: Number, default: 0 },
    isShowSelect: { type: Boolean, default: false },
    pagination: { type: Object, default: () => ({ page: 1, first: 5 }) },
    loading: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
  },

  data() {
    return {
      visibleHeaders: [],
    };
  },

  computed: {
    selectedRows: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  watch: {
    visibleHeaders(val) {
      this.$emit("change-visible-headers", val);
    },
  },

  methods: {
    changePerPage(perPage) {
      this.$emit("change-per-page", perPage);
    },

    changePage(page) {
      this.$emit("change-page", page);
    },
  },
};
</script>

<style scoped></style>
