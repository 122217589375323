<template>
  <AppTable
    :headers="$options.headers"
    :items="tableItems"
    :pagination="pagination"
    :items-server-length="eventPartners.paginatorInfo.total"
    :loading="$apollo.queries.eventPartners.loading"
    @change-visible-headers="onChangeVisibleHeaders"
    @change-page="onChangePage"
    @change-per-page="onChangePerPage"
    @click-row="onClickRow"
  >
    <template #body.prepend>
      <td
        v-show="visibleHeaders.includes($options.VISIBLE_HEADERS_VALUES.id)"
      ></td>
      <td
        v-show="visibleHeaders.includes($options.VISIBLE_HEADERS_VALUES.name)"
      >
        <v-text-field
          v-model="filters.public_name"
          label="Название"
          dense
          single-line
          hide-details
        />
      </td>
      <td
        v-show="visibleHeaders.includes($options.VISIBLE_HEADERS_VALUES.city)"
      ></td>
      <td
        v-show="
          visibleHeaders.includes($options.VISIBLE_HEADERS_VALUES.dateFrom)
        "
      ></td>
      <td
        v-show="visibleHeaders.includes($options.VISIBLE_HEADERS_VALUES.status)"
      >
        <v-text-field
          v-model="filters.status"
          label="Статус"
          dense
          single-line
          hide-details
        />
      </td>
      <td
        v-show="
          visibleHeaders.includes(
            $options.VISIBLE_HEADERS_VALUES.participantCount
          )
        "
      ></td>
    </template>
  </AppTable>
</template>

<script>
import AppTable from "@components/base/app-table/app-table";
import { debounce } from "@/utils";
import { BASE_DEBOUNCE_DELAY } from "@/utils/constants";
import {
  EventPartnerEnum,
  SortOrder,
  HasPartnerEnum,
} from "@generated/graphql";
import { PartnerEvents } from "@graphql/queries/EventPartners.graphql";
import {
  getPartnerEventsFilters,
  getPartnerEventsTableItems,
  PARTNERS_EVENTS_TABLE_HEADERS,
  VISIBLE_HEADERS_VALUES,
} from "@/domain/event";
import { Routes } from "@/types/core";

export default {
  components: {
    AppTable,
  },

  props: {
    partnerId: { type: String, default: "", required: true },
  },

  apollo: {
    eventPartners: {
      query: PartnerEvents,
      variables() {
        return {
          first: 5,
          page: 1,
          orderBy: [{ column: EventPartnerEnum.Id, order: SortOrder.Desc }],
          hasPartner: {
            column: HasPartnerEnum.Id,
            value: this.partnerId,
          },
        };
      },
    },
  },

  headers: PARTNERS_EVENTS_TABLE_HEADERS,

  VISIBLE_HEADERS_VALUES,

  data() {
    return {
      pagination: {
        page: 1,
        first: 5,
      },
      eventPartners: {
        paginatorInfo: {
          perPage: 5,
          currentPage: 1,
          total: 0,
          count: 0,
        },
        data: [],
      },
      visibleHeaders: [],
      filters: { public_name: "", status: "" },
    };
  },

  computed: {
    tableItems() {
      return getPartnerEventsTableItems(this.eventPartners.data);
    },
  },

  watch: {
    filters: {
      handler() {
        this.onChangeFilters();
      },
      deep: true,
    },
  },

  methods: {
    onChangeFilters: debounce(async function () {
      this.pagination.page = 1;

      const filters = getPartnerEventsFilters(this.filters);

      await this.$apollo.queries.eventPartners.refetch({
        page: this.pagination.page,
        first: this.pagination.first,
        ...filters,
      });
    }, BASE_DEBOUNCE_DELAY),

    async onChangePage(page) {
      this.pagination.page = page;

      await this.$apollo.queries.eventPartners.refetch({
        page: this.pagination.page,
        first: this.pagination.first,
      });
    },

    async onChangePerPage(perPage) {
      this.pagination.first = perPage;
      this.pagination.page = 1;

      await this.$apollo.queries.eventPartners.refetch({
        page: this.pagination.page,
        first: this.pagination.first,
      });
    },

    onChangeVisibleHeaders(headers) {
      this.visibleHeaders = headers.map((h) => h.value);
    },

    onClickRow(row) {
      this.$router.push({
        name: Routes["eventsID"],
        params: { id: row.id },
      });
    },
  },
};
</script>

<style>
.v-card__title {
  white-space: nowrap;
}
</style>
