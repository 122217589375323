<template>
  <div>
    <h2 class="mb-4">Редактирование партнера</h2>
    <page-section subheader="Атрибуты">
      <ValidationObserver ref="form">
        <v-row>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" rules="required">
              <v-text-field
                v-model="form.name"
                label="Название *"
                placeholder="Название *"
                background-color="white"
                outlined
                required
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" rules="url">
              <v-text-field
                v-model="form.url"
                label="Сайт"
                placeholder="Сайт"
                background-color="white"
                :error-messages="errors"
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ImageInput v-model="form.logotype" label="Логотип партнера" />
          </v-col>
        </v-row>
      </ValidationObserver>
    </page-section>
    <div>
      <page-section subheader="Список циклов Партнёра">
        <PartnerCyclesTable :partner-id="$route.params.id" />
      </page-section>
      <page-section subheader="Список мероприятий Партнёра">
        <PartnerEventsTable :partner-id="$route.params.id" />
      </page-section>
    </div>
    <PageControlsBar ref="controls">
      <div class="d-flex"></div>
      <v-btn
        color="primary"
        class="mr-4"
        :disabled="isLoading"
        depressed
        @click="onSave"
      >
        Сохранить
      </v-btn>
      <v-btn
        class="mr-4"
        text
        :disabled="isLoading"
        @click="$router.push({ name: 'admin/partners' })"
      >
        Отменить
      </v-btn>
      <v-dialog persistent max-width="300px" v-model="isDeleteModalShown">
        <template #activator="{ on }">
          <v-btn
            v-show="isDeleteBtnShown"
            :disabled="isLoading"
            class="ml-auto"
            color="red"
            text
            v-on="on"
          >
            Удалить
          </v-btn>
        </template>

        <v-card>
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-card-text>Это действие невозможно отменить</v-card-text>
          <v-card-actions class="py-4">
            <v-btn @click="isDeleteModalShown = false" text small>
              Отмена
            </v-btn>
            <v-btn color="red" text small @click="onClickDelete">
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </PageControlsBar>
    <v-snackbar v-model="isUpdateSuccess" color="green" timeout="3000">
      Данные обновлены
    </v-snackbar>
    <v-snackbar v-model="isLoading" timeout="3000">
      <v-progress-circular
        :size="20"
        :width="2"
        color="white"
        indeterminate
        class="mr-2"
      />
      Сохранение данных
    </v-snackbar>
    <v-snackbar v-model="isUpdateFailed" color="red" timeout="3000">
      Произошла ошибка
    </v-snackbar>
  </div>
</template>

<script>
import PageSection from "@components/page/Section.vue";
import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";
import PageControlsBar from "@components/page/ControlsBar.vue";
import {
  SinglePartner,
  UpdatePartner,
  DeletePartner,
} from "@/graphql/queries/Partners.graphql";
import PartnerEventsTable from "@components/PartnerEventsTable";
import PartnerCyclesTable from "@components/PartnerCyclesTable";
import { Routes } from "@/types/core";

export default {
  components: {
    PartnerCyclesTable,
    PartnerEventsTable,
    PageSection,
    ImageInput,
    PageControlsBar,
  },

  apollo: {
    partner: {
      query: SinglePartner,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },

  data() {
    return {
      form: {
        name: "",
        url: "",
        logotype: "",
      },
      isDeleteModalShown: false,
      partner: null,
      isLoading: false,
      isUpdateFailed: false,
      isUpdateSuccess: false,
    };
  },

  computed: {
    isDeleteBtnShown() {
      if (!this.partner) return false;
      return (
        !this.partner.eventPartnersCount &&
        !this.partner.eventCyclePartnersCount
      );
    },
  },

  watch: {
    partner: {
      handler(val) {
        this.form = { ...val };
      },
      deep: true,
    },
  },

  created() {
    if (!this.$store.getters["session/isAllow"]("EDIT_ITEM"))
      this.$router.push({ name: Routes["noRights"] });
  },

  methods: {
    async onSave() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      try {
        this.isLoading = true;
        const input = {
          id: this.$route.params.id,
          name: this.form.name,
          url: this.form.url,
        };
        if (this.form.logotype instanceof File || !this.form.logotype) {
          input.logotype = this.form.logotype;
        }

        await this.$apollo.mutate({
          mutation: UpdatePartner,
          variables: { input },
        });
        this.isUpdateSuccess = true;
      } catch (e) {
        this.isUpdateFailed = true;
      } finally {
        this.isLoading = false;
      }
    },

    async onClickDelete() {
      try {
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: DeletePartner,
          variables: {
            id: this.$route.params.id,
          },
        });
        await this.$router.push({ name: Routes["admin/partners"] });
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
